<!--
/**
 *@author clark
 *@date 2023/2/20
 *  商户管理 商户详情
*/
-->
<template>
  <scroll-layout>
    <template #header>
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageTopButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
      <div class="fleet-match-content">
        <YkcDetailPlus class="box-card" style="margin-bottom: 10px">
          <div slot="header">
            <span>商户信息</span>
          </div>
          <div class="baseInfo">
            <div class="content">
              <div class="top">
                <div>
                  <span class="label">商户名称:</span>
                  <span class="value">
                    {{ dealData(detail.name) }}
                  </span>
                </div>
                <div>
                  <span class="label">所属城市:</span>
                  <span class="value">
                    {{ dealData(detail.provinceName) + dealData(detail.districtName) }}
                  </span>
                </div>
                <div>
                  <span class="label">公司名称:</span>
                  <span class="value">
                    {{ dealData(detail.companyName) }}
                  </span>
                </div>
                <div>
                  <span class="label">商户管理员:</span>
                  <span class="value">
                    {{ dealData(detail.manageName) }}
                  </span>
                </div>
                <div>
                  <span class="label">统一社会信用代码:</span>
                  <span class="value">
                    {{ dealData(detail.socialCreditCode) }}
                  </span>
                </div>
                <div>
                  <span class="label">联系方式:</span>
                  <span class="value">
                    {{ dealData(detail.contactInfo) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </YkcDetailPlus>
        <YkcDetailPlus class="box-card" v-if="type == 'union'">
          <div slot="header">
            <span>秘钥信息</span>
          </div>
          <div class="baseInfo">
            <div class="content">
              <div class="top">
                <div>
                  <span class="label">机构秘钥:</span>
                  <span class="value">
                    {{ dealData(detail.secretKey) }}
                  </span>
                </div>
                <div>
                  <span class="label">签名秘钥:</span>
                  <span class="value">
                    {{ dealData(detail.signKey) }}
                  </span>
                </div>
                <div>
                  <span class="label">消息秘钥:</span>
                  <span class="value">
                    {{ dealData(detail.dataEncryptionKey) }}
                  </span>
                </div>
                <div>
                  <span class="label">接口URL:</span>
                  <span class="value">
                    {{ dealData(detail.interconnectionUrl) }}
                  </span>
                </div>
                <div>
                  <span class="label">初始化向量:</span>
                  <span class="value">
                    {{ dealData(detail.initializationVector) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </YkcDetailPlus>
      </div>
      <section class="card-info-area" v-if="type == 'platForm'">
        <div class="title">经营属性</div>
        <div class="content">
          <div class="common-text">
            <span class="common-label-key">开票种类：</span>
            <span class="common-label-val">
              {{
                detail.billType == 1 ? '普通发票' : detail.billType == 2 ? '增值税专用发票' : '—'
              }}
            </span>
          </div>
          <div class="img">
            <span class="img-label">营业执照：</span>
            <el-image
              v-if="detail.businessLicence"
              style="width: 120px; height: 120px"
              :src="detail.businessLicence"></el-image>
            <span v-else style="width: 120px">————</span>
          </div>
          <div class="img">
            <span class="img-label">站点logo：</span>
            <el-image
              v-if="detail.stationLogo"
              style="width: 120px; height: 120px"
              :src="detail.stationLogo"></el-image>
            <span v-else style="width: 120px">————</span>
          </div>
          <div class="img">
            <span class="img-label">平台logo：</span>
            <el-image
              v-if="detail.platformLogo"
              style="width: 120px; height: 120px"
              :src="detail.platformLogo"></el-image>
            <span v-else style="width: 120px">————</span>
          </div>
        </div>
      </section>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        title="电站列表"
        operateFixedType="right"
        :data="tableData"
        :columns="tableColumns">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :page-size.sync="pageInfo.size"
          :current-page.sync="pageInfo.current"
          @size-change="handlePaginationSizeChange"
          @current-change="handlePaginationCurrentChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog';
  import { offlineExport, dealData, code } from '@/utils';
  import { CustomDriverAccounts, operatorData, marketManage } from '@/service/apis';

  export default {
    name: 'CustomDriverAccountsDetail',
    components: {},
    data() {
      return {
        id: '',
        type: '', // platform 平台商户 跳转直联电站 ，union 跳转互联电站
        detail: {
          operatorId: null,
          name: '',
          companyName: '',
          districtId: null,
          provinceId: null,
          socialCreditCode: '',
          manageName: '',
          contactInfo: '',
          billType: null,
          operatorType: '',
          businessLicence: '',
          stationLogo: '',
          platformLogo: '',
          operatorRemark: '',
          loginUrl: '',
          extId: null,
          secretKey: null,
          dataEncryptionKey: null,
          initializationVector: null,
          signKey: null,
          interconnectionUrl: null,
          lastChargeTime: '',
        },
        dictionary: {},
        headerInfo: {},
        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            enabled: () =>
              this.type === 'platForm'
                ? code('URL:asset:station:direct')
                : code('URL:asset:station:connection'),
            text: '管理电站',
            type: 'plain',
            handleClick: () => {
              this.jumpToStation(this.type, this.id);
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: 'ID', prop: 'stationId', minWidth: '100px' },
          { label: '电站名称', prop: 'name', minWidth: '100px' },
          { label: '电站地址', prop: 'detailAddress', minWidth: '100px' },
          { label: '入驻时间', prop: 'createTime', minWidth: '100px' },
          { label: '归属商户', prop: 'operatorName', minWidth: '100px' },
          {
            label: '终端数量',
            prop: 'directTerminalNum',
            minWidth: '150px',
            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div class="flex">
                    <div style="margin-right:8px">
                      <span class="row-item row-green">直</span> {row.directTerminalNum ?? '-'}
                    </div>
                    <div>
                      <span class="row-item row-blue">交</span> {row.alternateTerminalNum ?? '-'}
                    </div>
                  </div>
                );
              },
            },
          },
          {
            label: '电站状态',
            prop: 'startStatus',
            minWidth: '120px',
            scopedSlots: {
              default: ({ row }) => {
                return row.startStatus === 1 ? (
                  <span class={['userStatus', 'status-blue']}>启用</span>
                ) : (
                  <span class={['userStatus', 'status-red']}>停用</span>
                );
              },
            },
          },
        ],

        pageInfo: {
          total: 0,
          current: 0,
          size: 10,
        },
        searchParams: {
          stationOperatorId: '',
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    created() {
      const { id, type } = this.$route.query;
      this.id = id;
      this.type = type;
      this.searchParams.stationOperatorId = id;
      this.getDetail();
      this.queryHeaderInfo();
      this.searchTableList();
    },
    methods: {
      dealData,
      handleChange(event, data) {
        console.log('event:', event);
        console.log('data:', data);
      },
      changeStateSearch() {
        this.pageInfo.current = 0;
        this.searchTableList();
      },
      /**
       * @function queryHeaderInfo
       * @desc 查询头部新消息
       * */
      queryHeaderInfo() {
        CustomDriverAccounts.detailHeaderInfo({ userId: this.id }).then(res => {
          this.headerInfo = res || {};
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const customDriverAccountsDetailReqParams = {
          ...this.searchParams,
          current: this.pageInfo.current,
          size: this.pageInfo.size,
        };
        marketManage.stationFindByPage(customDriverAccountsDetailReqParams).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        // CustomDriverAccounts.exportOfFlowList('代理人佣金列表', this.searchParams);
        offlineExport(
          {
            downloadType: 'clientDriverFlowList',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
      jumpToStation(type, id) {
        console.log(type);
        this.$router.push({
          path:
            type === 'platForm'
              ? '/plantAssets/powerStationManagement/directConnectionStation/List'
              : '/plantAssets/powerStationManagement/interconnectionStation/List',
          query: {
            id,
            type,
          },
        });
      },
      getDetail() {
        // 编辑回显
        if (this.id) {
          operatorData
            .operatorDetail({ id: this.id })
            .then(res => {
              this.detail = {
                ...res,
              };
              this.pictureLicence.url = res.businessLicence;
              this.pictureStationLogo.url = res.stationLogo;
              this.picturePlatformLogo.url =
                'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281';
              this.getTreeData(true, res.configIds);
            })
            .catch(() => {});
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .fleet-match-content {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-flow: column;
    .box-card {
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }

      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }

  .card-info-area {
    width: 100%;
    padding: 10px;

    background: #ffffff;
    box-shadow: 1px 1px 4px 1px rgba(94, 94, 94, 0.09);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #000;
    }

    .content {
      min-height: 120px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;
      .img {
        display: flex;
        justify-content: center;
        justify-items: center;
      }
      .img-label {
        margin-left: 30px;
        color: #9b9b9b;
        font-size: 12px;
        margin-right: 10px;
        align-self: center;
        text-align: center;
      }
      .common-icon {
        display: inline-flex;
        box-sizing: border-box;
        width: 70px;
        height: 70px;
        line-height: 70px;
        margin-left: 10px;
        background: rgba(77, 140, 253, 0.1);
        border-radius: 14px;

        align-content: center;
        text-align: center;
        align-items: center;
        justify-content: center;
        .big-icon {
          width: 32px;
          height: 32px;
          display: inline-block;
          background-image: url('~@/assets/images/financial/customDriver/purse@2x.png');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
      .common-text {
        display: inline-flex;
        flex-direction: row;
        .common-label-key {
          display: inline-block;
          width: 60px;
          height: 17px;
          line-height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
        .common-label-val {
          display: inline-block;
          height: 17px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 17px;
          margin-left: 8px;
        }
      }
    }
  }
  .ykc-table {
    .el-table .el-table__row {
      .cell .userStatus {
        padding-left: 8px;
        position: relative;
      }

      .cell .userStatus::after {
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: #d0021b;
        position: absolute;
        top: 5.5px;
        left: 0;
      }

      .cell .status-blue::after {
        background: #4d8cfd;
      }

      .cell .status-green::after {
        background: #02c498;
      }

      .cell .status-black::after {
        background: #9b9b9b;
      }
    }
    .sealBtn {
      color: #4d8cfd;
      cursor: pointer;
    }
  }
  .row-item {
    padding: 0px 4px;
    font-size: 10px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    border-radius: 7px;
    display: inline-block;
  }

  .row-green {
    background: linear-gradient(180deg, #07c160 0%, #02e495 100%);
  }

  .row-blue {
    background: linear-gradient(180deg, #1779fe 0%, #72aeff 100%);
  }
</style>
